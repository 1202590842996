import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MailDataModel } from '../models/mail-data.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MailDataValue } from '../models/mail-data.value';

export const invoiceMailsActions = createActionGroup({
    source: 'Invoice Mails',
    events: {
        'Get Invoice Mails': emptyProps(),
        'Get Invoice Mails Success': props<{ data: MailDataModel }>(),
        'Get Invoice Mails Error': props<{ error: HttpErrorResponse }>(),

        'Update Invoice Mails': props<{ data: MailDataValue }>(),
        'Update Invoice Mails Success': emptyProps(),
        'Update Invoice Mails Error': props<{ error: HttpErrorResponse }>(),
    },
});
