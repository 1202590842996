import { inject, Injectable } from '@angular/core';
import { selectInvoiceMails, selectLoaded } from '../selectors/invoice-mails.selectors';
import { Store } from '@ngrx/store';
import { invoiceMailsActions } from '../actions/invoice-mails.actions';
import { MailDataValue } from '../models/mail-data.value';

@Injectable({
    providedIn: 'root',
})
export class InvoiceMailsFacade {
    private store = inject(Store);

    invoiceMails$ = this.store.select(selectInvoiceMails());
    loaded$ = this.store.select(selectLoaded);

    getInvoiceMails() {
        this.store.dispatch(invoiceMailsActions.getInvoiceMails());
    }

    updateInvoiceMails(data: MailDataValue) {
        this.store.dispatch(invoiceMailsActions.updateInvoiceMails({ data }));
    }
}
